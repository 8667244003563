<template>
    <div class="container">
        <b-card class="mt-4">
            <b-card-title>
                <h3>Orders</h3>
            </b-card-title>
<div class="responsive-table">
            <vue-table :data="data" :fields="fields" :url="url" ref="table">
                <template #action="{rowData}">
                    <b-button-group>
                        <b-button size="sm" variant="danger" @click="RemoveItem(rowData)">Remove</b-button>
                    </b-button-group>
                </template>
            </vue-table>
</div>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'OrderedItems',
    components: {},
    data () {
        return {
            url: urls.shopOrder.orderdetails + '?id=' + this.$route.params.id,
            data: [],
            model: {
                status: '',
                id: ''
            },
            rules: {
                status: {
                    required: true
                },
                id: {
                    required: true
                }
            },
            fields: [
                {
                    name: 'product_name',
                    title: 'Product',
                    sortField: ''
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    sortField: ''
                },
                {
                    name: 'unit_price',
                    title: 'Unit Price',
                    sortField: ''
                },
                {
                    name: 'total_price',
                    title: 'Total',
                    sortField: ''
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },
        setData (response) {
            this.$refs.table.refreshTable();
            this.order_items = response.data.order_items;
            console.log(this.order_items);
        },
        async loadData () {
            const response = await axios.get(urls.shopOrder.orderdetails + '?id=' + this.$route.params.id);
            this.setData(response);
        },
        async RemoveItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id, order_id: rowData.order_id };
                const response = await axios.form(urls.shopOrder.RemoveItem, params);
                console.log(response);
                this.$refs.table.refreshTable();
            }
        }
    }
};

</script>
